// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avionics-team-js": () => import("./../../../src/pages/avionics_team.js" /* webpackChunkName: "component---src-pages-avionics-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programming-team-js": () => import("./../../../src/pages/programming_team.js" /* webpackChunkName: "component---src-pages-programming-team-js" */),
  "component---src-pages-propulsion-team-js": () => import("./../../../src/pages/propulsion_team.js" /* webpackChunkName: "component---src-pages-propulsion-team-js" */),
  "component---src-pages-structures-team-js": () => import("./../../../src/pages/structures_team.js" /* webpackChunkName: "component---src-pages-structures-team-js" */),
  "component---src-pages-subteams-js": () => import("./../../../src/pages/subteams.js" /* webpackChunkName: "component---src-pages-subteams-js" */)
}

